import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Navigation from 'components/layout/Navigation/index'
import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Automatyka/About'
import Tiles from 'components/layout/Sections/Pages/Automatyka/Tiles'

import slugify from 'slugify'

const breadcrumbs = {
  mobile: [
    {
      label: 'Automatyka',
      link: '/automatyka/',
    },
    {
      label: 'Napędy do bram przesuwnych',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Automatyka',
      link: '/automatyka/',
    },
    {
      label: 'Napędy do bram przesuwnych',
    },
  ],
}

const NapedyDoBramPrzesuwnychPage: React.FC<PageProps> = ({ data }) => {
  const PAGE_SEO = data?.wpPage?.seo
  const PAGE = data?.wpPage?.napeDyDoBramSkrzydlOwych

  const TILES = data?.allWpPage?.nodes?.map((item) => ({
    img: item.twist200e?.productsGallery[0]?.twist200eGalleryImg?.localFile
      ?.childImageSharp?.gatsbyImageData!,
    alt: item.twist200e?.productsGallery[0]?.twist200eGalleryImg?.altText!,
    link: `/automatyka/napedy-do-bram-przesuwnych/${slugify(
      item.twist200e?.productsTitle!,
      { lower: true }
    ).replace('+', '2')}/`,
    label: item.twist200e?.productsTitle!,
  }))

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={PAGE?.gateImg?.localFile?.childImageSharp?.gatsbyImageData!}
        imgDesktop={PAGE?.gateImg?.localFile?.childImageSharp?.gatsbyImageData!}
        headingMobile={PAGE?.gateTitle}
        headingDesktop={PAGE?.gateTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.gateDescription} />
      <Tiles tiles={TILES} />
    </Layout>
  )
}

export default NapedyDoBramPrzesuwnychPage

export const query = graphql`
  query NapedyDoBramPrzesuwnychPage {
    wpPage(slug: { eq: "napedy-do-bram" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      napeDyDoBramSkrzydlOwych {
        gateTitle
        gateDescription
        gateImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allWpPage(
      filter: {
        twist200e: {
          productsCategory: { regex: "/napęd do bram przesuwnych/" }
        }
      }
    ) {
      nodes {
        twist200e {
          productsTitle
          productsGallery {
            twist200eGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
